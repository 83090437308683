import style from "./index.module.css";
import { AiOutlineSearch, AiOutlineBell, AiOutlineUser, AiOutlinePlus,AiOutlineHome } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import ShowModalCriarAlgo from "../ShowModalCriarAlgo";
import ShowModalCriarShorts from "../ShowModalCriarShorts";
import {handleLogout, obterToken} from "../../global";
import apiUrl from "../../global";
import ModalPostagemUnica from '../ModalPostagemUnica';
import SearchModal from "./SearchModal/index";
import NotificacoesModal from "./NotificacoesModal";
import MenuTopo from "./MenuTopo";
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';
import { BsChat } from 'react-icons/bs'; // Importa o ícone de chat específico
import { GoBook } from "react-icons/go";


function PaginaInicialIcone({menuStyle}) { //Se já tiver na home, vai atualizar ao clicar no ícone de home
  const location = useLocation();
  const navigate = useNavigate();

  function onClickIcon(){
    if (location.pathname === '/') {
      // Se já estiver na página inicial, atualize a página
      window.location.reload();
    } else {
      // Caso contrário, navegue para a página inicial
      navigate('/');
    }
  }

  return(
    menuStyle=="conventional"?
    <div className={style.divIcon} onClick={onClickIcon}>
      <AiOutlineHome className={style.icon} /><p>Página inicial</p>
    </div>:
    <div className={style.divIconShort} onClick={onClickIcon}>
    <AiOutlineHome className={style.icon} />
  </div>
  )
}

function MenuShortIcone({menuStyle}){
  const navigate = useNavigate();
  return(
    menuStyle=="conventional"?
    <div className={style.divIcon} onClick={()=>navigate('/shorts')}>
      <AiOutlinePlayCircle className={style.icon} /><p>Vídeos shorts</p>
    </div>:
    <div className={style.divIconShort} onClick={()=>navigate('/shorts')}>
    <AiOutlinePlayCircle className={style.icon} />
  </div>
  )
}


export default function MenuEsquerdo({user,setUser, socket, forcarShortMobile, hideMobileMenuBottom}){
  const [modalCriarAlgo, setModalCriarAlgo] = useState(false); //Handler pra exibir modal
  const [modalCriarShorts, setModalCriarShorts] = useState(false);
  const [fadeBackground, setFadeBackground] = useState(false);
  const [fadeBackgroundNoColor, setFadeBackgroundNoColor] = useState(false);
  const [searchModalHandler, setSearchModalHandler] = useState(false); 
  const [notificacoesHandler, setNotificacoesHandler] = useState(null); //N mude o null sem entender o impacto no código
  const [countNovasNotificacoes, setCountNovasNotificacoes] = useState(0);
  const [postagemUnicaDados, setPostagemUnicaDados] = useState(''); //Guarda dados da postagem
  const [ShowModalPostagemUnicaHandler,setShowModalPostagemUnicaHandler] = useState(false); //Ativa modal postagem unica
  const [urlUid, setUrlUid] = useState(null); //Guarda parametro da url, ao ser alterado chama useEffect
  const [janelaW, setjanelaW] = useState(window.innerWidth);
  const short = forcarShortMobile?.short || 880; //Desktop é > short
  const mobile = forcarShortMobile?.mobile || 650;
  const [menuStyle, setMenuStyle] = useState(janelaW<=mobile?"mobile":janelaW<short?"short":"conventional");
  const navigate = useNavigate(); 
  const location = useLocation();


  useEffect(()=>{ /*Atualizar ultima notificacao vista no 'user' ao fechar modal notificacoes (notificacoeHandler=false)*/
    async function atualizarUser(){
          const token = await obterToken();
          try {
            const response = await fetch(`${apiUrl}/atualizarUltmNotificVista`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
        
            if (!response.ok) {
                // Se a resposta não for ok (código não está na faixa 200-299)
                const errorMessage = `Erro: ${response.status} ${response.statusText}`;
                console.error(errorMessage);
                throw new Error(errorMessage);
            }
            setCountNovasNotificacoes(0);
        } catch (error) {
            throw error
        }
      }
      if(notificacoesHandler===false){atualizarUser()}
  },[notificacoesHandler])

  //Escutando socket para novas notificacoes
  useEffect(() => {
    if (!socket) return;

    const handleNotificacao = (data) => {
      if (data.countNovasNotificacoes !== undefined) {
        setCountNovasNotificacoes(data.countNovasNotificacoes);
      }
    };

    socket.on('novasNotificacoes', handleNotificacao);

    return () => {
      socket.off('novasNotificacoes', handleNotificacao);
    };
  }, [socket, setCountNovasNotificacoes]);


  //Escutando resize da janela
  window.addEventListener('resize', () => { /*Listener de resize da janela pra mudar menu pro mobile */
    let larguraJanela = window.innerWidth;
    setjanelaW(larguraJanela);
    if(janelaW<mobile){
      setMenuStyle("mobile");
      return;
    }else if(janelaW<short){
      setMenuStyle("short");
      return;
    }else{
      setMenuStyle("conventional");
    }
  });
 
  /***************Pegar postagem da url e mandar postagem unica abrir********************/
    useEffect(() => {
      function handleUrlChange() {
        const params = new URLSearchParams(window.location.search);
        const parametroUrl = params.get('p');
        if (parametroUrl) {
          setUrlUid(parametroUrl);
        }
      }

      window.addEventListener('popstate', handleUrlChange); //Listener de ao mudar url do navegador

      /* eslint-disable no-restricted-globals */
      const originalPushState = history.pushState;
      const originalReplaceState = history.replaceState;

      history.pushState = function(...args) { //Ao fazer push url
        originalPushState.apply(this, args);
        handleUrlChange();
      };

      history.replaceState = function(...args) { //Ao fazer replace pra url
        originalReplaceState.apply(this, args);
        handleUrlChange();
      };
      /* eslint-disable no-restricted-globals */
      handleUrlChange(); // Primeiro load

      return () => {
        window.removeEventListener('popstate', handleUrlChange);
        history.pushState = originalPushState;
        history.replaceState = originalReplaceState;
      };
      
    }, [location]);

    useEffect(() => { /*Pega postagens se urlUid tiver algo*/
      async function obterPostagemUnica() {
        const token = await obterToken(); 
        try {        
          const response = await fetch(`${apiUrl}/getPostByUid`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              urlUid, // Envia o urlUid para a API
            }),
          });

          if (!response.ok) {
            console.log("!Erro!", response.message);
          }

          const resultado = await response.json();
          if(resultado){
            setPostagemUnicaDados(resultado);
          }
        } catch (error) {
          console.error('Erro ao buscar postagens:', error);
        } finally{
          setUrlUid(null);

          // Adiciona uma nova entrada ao histórico antes de abrir modal, pois se vir da url, qd clicar pra voltar no mobile,
          //volta pra tela inicial e não pro site q trouxe o usuário. Funciona tb pra qd abrir notificacoes voltar pra tela q tava
          const currentUrl = new URL(window.location.href);
          currentUrl.search = ''; // Remove a query string (tudo depois de "?")
          currentUrl.hash = '';   // Remove o hash (tudo depois de "#")
          window.history.replaceState(null, '', currentUrl.href); // Substitui a URL atual com a versão sem parâmetros

          setShowModalPostagemUnicaHandler(true);
          
        }
      }

      if (urlUid) {
        obterPostagemUnica();
      }
      
    }, [urlUid]); 
  /*****************************************************************************************/
 

    useEffect(() => { /*Ativa fadeBackground (com cor) se estiver no modal criar postagens/shorts*/
      /*
      Pela complexidade maior do fadeBackgroundNoColor, ele é ativado direto no OnClick do botão
      */
      if (modalCriarAlgo || modalCriarShorts) {
        setFadeBackground(true);
        setSearchModalHandler(false); //Ao criar postagem, retira o search modal pra n atrapalhar
        if(janelaW>short){
          setMenuStyle("conventional"); //Volta menu pro conventional
        }
      }
    }, [modalCriarAlgo,modalCriarShorts]);
    

  function onClickFadeBackground() { /*Função ao clicar no background*/
    setModalCriarAlgo(false);
    setFadeBackground(false);
    setModalCriarShorts(false);
  }

  async function onClickFadeBackgroundNoColor() { /*Ao clicar no background sem cor (do criar posts/shorts) */
    if(searchModalHandler){
      onClickSearchModal();
    }else if(notificacoesHandler){
      onClickNotificacoes();
    }

  }

  function onClickSearchModal(){
    if(searchModalHandler==false){ //Quero ativar o searchModal..
      setSearchModalHandler(true); //Ativo aq
      if(janelaW>short){ //Se tiver em desktop, mude pra short pra exibir modal
        setMenuStyle("short");
      }
      setFadeBackgroundNoColor(true); //Ativa o fade background
      if(notificacoesHandler){ //Tem q ter if, pq se ja ta false mas setar false dnv, executa useEffect
        setNotificacoesHandler(false); //Fecha o modal do notificacoes ao abrir o do search
      }
    }else{ //Quero fechar o modal
      setSearchModalHandler(false); //Fecho o controlador
      if(janelaW>short){ //Se tiver no desktop, volte pra conventional
        setMenuStyle("conventional");
      }
      setFadeBackgroundNoColor(false); //Desliga fade background
    }

  }

  function onClickNotificacoes(){
    console.log("chegou aq"); 
    if(notificacoesHandler==false || notificacoesHandler==null){ //Verifica null tb pq começa null 
      setNotificacoesHandler(true);
      if(searchModalHandler){
        setSearchModalHandler(false);
      } 
      if(janelaW>short){ //Se tiver em desktop, mude pra short pra exibir modal
        setMenuStyle("short");
      }
      setFadeBackgroundNoColor(true);
    }else {
      setNotificacoesHandler(false);
      if(janelaW>short){ //Só volta pro menú convencional se n tiver em mobile. 800px pq é o valor q usamos no css tb.
        setMenuStyle("conventional");
      }
      
      setFadeBackgroundNoColor(false);
    }
  }

  function menu(){
  
    function showMenuConventional(){
      return(
        <div className={style.menuLateral}>
          {/*Logo*/}
          <div className={style.hevelim}>
            <h2>Hevelim</h2>
          </div>

          {/*Home*/}
          <PaginaInicialIcone menuStyle={menuStyle}/>

          {/*Menu biblia*/}
          <div className={style.divIcon} onClick={()=>navigate('/biblia')}>
            <GoBook className={style.icon}/><p>Leia a Biblia</p>
          </div>

          {/*Search*/}
          <div className={style.divIcon} onClick={onClickSearchModal}>
            <AiOutlineSearch className={style.icon} /><p>Encontrar</p>
          </div>



          {/*Notificacoes*/}
          <div className={style.divIcon} onClick={onClickNotificacoes}>
            <AiOutlineBell className={style.icon}/><p>Notificações</p>
            {countNovasNotificacoes>0&&<span className={style.NotificacaoNumero}>{countNovasNotificacoes}</span>}
          </div>

          {/*Shorts*/}
          <MenuShortIcone menuStyle={menuStyle}/>


          
          
          {/*seu perfil*/}
          <div className={style.divIcon} onClick={()=>window.location.assign(`/${user&&user.username}`)/*navigate(`/${user&&user.username}`)*/}>
            <AiOutlineUser className={style.icon} /><p>Meu perfil</p>
          </div>

          {/*Mensagens*/}
          <div className={style.divIcon} onClick={()=>navigate('/mensagens')}>
            <BsChat className={style.icon} /><p>Mensagens</p>
          </div>

          {/* Criar shorts
          <div className={style.divIcon} onClick={()=>setModalCriarShorts(true)}>
            <FiVideo className={style.icon} /><p>Criar shorts</p>
          </div> */}

          {/*Criar postagens*/}
          <div className={style.divIcon} onClick={() => setModalCriarAlgo(true)}>
            <AiOutlinePlus className={style.icon} /><p>Criar publicação</p>
          </div>

          {/*Funções adm*/}
          {
            (user.claims=="admin" || user.claims=="moderador")&&
            <span>
              <div className={style.divIcon} onClick={()=>{navigate('/moderador');}}>
                <FaStar className={style.icon} />
                <p>Moderador</p>
              </div>
            </span>
          }
          {
            (user.claims=="admin" || user.claims=="moderador")&&
            <span>
              <div className={style.divIcon} onClick={()=>{navigate('/ajudante');}}>
                <FaStar className={style.icon} />
                <p>Ajudante da plataforma</p>
              </div>
            </span>
          }

          {/*Logout*/}
          <div className={style.divIcon} onClick={()=>handleLogout(navigate)}>
            <BiLogOut className={style.icon} /><p>Sair</p>
          </div>
        </div>
      )
    }
  
    function showMenuShort(){
      return(
        <div className={style.menuLateralShort}>
            {/*Logo*/}
            <div className={style.hevelim}> <p>HL</p> </div>

            {/*Página inicial*/}
            <PaginaInicialIcone menuStyle={menuStyle}/>

              {/*Menu biblia*/}
            <div className={style.divIconShort} onClick={()=>navigate('/biblia')}>
              <GoBook className={style.icon}/>
            </div>

            {/*Menú search*/}
            <div className={style.divIconShort} onClick={onClickSearchModal}>
              <AiOutlineSearch className={style.icon} />
            </div>

            {/*Menu notificacoes*/}
            <div className={style.divIconShort} onClick={onClickNotificacoes}>
                <AiOutlineBell className={style.icon}/>
                {countNovasNotificacoes>0&&<span className={style.NotificacaoNumeroShort}>{countNovasNotificacoes}</span>}
            </div>

            {/*Menu criar novos shorts */}
            <MenuShortIcone menuStyle={menuStyle}/>

            {/*Menu chats*/}
            <div className={style.divIconShort}>
                <BsChat className={style.icon} />
            </div>


            <div className={style.divIconShort} onClick={()=>window.location.assign(`/${user&&user.username}`)/*navigate(`/${user&&user.username}`)*/}>
                <AiOutlineUser className={style.icon} />
            </div>
            {/* <div className={style.divIconShort} onClick={()=>setModalCriarShorts(true)}>
              <FiVideo className={style.icon} />
            </div> */}
            <div className={style.divIconShort} onClick={() => setModalCriarAlgo(true)}>
              <AiOutlinePlus className={style.icon} />
            </div>
            {
              (user.claims=="admin" || user.claims=="moderador")&&
              <div className={style.divIconShort} onClick={()=>{navigate('/moderador')}}>
                <FaStar className={style.icon} />
              </div>
            }
            <div className={style.divIconShort} onClick={()=>handleLogout(navigate)}>
              <BiLogOut className={style.icon} />
            </div>
        </div>
      )
    } 

    function showMenuMobile(){
    
      /*
      Perceba que as classes são as mesmas do short. No css usado media query pra alterá-los de acordo.
      N há necessidade de mais estilos. Contudo, o menuMobile foi separado do menuShort pra facilitar
      a personalização.
      
      */
      return(
        <div className={style.menuLateralShort}>
            {/*Página inicial*/}
            <PaginaInicialIcone menuStyle={menuStyle}/>

            {/*Biblia*/}
            <div className={style.divIconShort} onClick={()=>navigate('/biblia')}>
              <GoBook className={style.icon}/>
            </div>

            {/*Abrir video shorts*/}
            <MenuShortIcone menuStyle={menuStyle}/>

            {/*Abrir chats*/}
            <div className={style.divIconShort} onClick={()=>navigate('/mensagens')}>
              <BsChat className={style.icon} />
            </div>

            {/*Seu perfil*/}
            <span className={style.divIconShort}>
              <div className={style.profileIcon} onClick={()=>window.location.assign(`/${user&&user.username}`)/*navigate(`/${user&&user.username}`)*/}>
                <img src={user.profile_image} className={style.profileIconImg}/>
              </div>
            </span>
        </div>
      )
    } 
    return(
      <>     
        {/*Chamando menú lateral de acordo com qual menú vc quer*/}
       {menuStyle=="conventional" && showMenuConventional()}
       {menuStyle=="short" && showMenuShort()}
       {menuStyle=="mobile" && (!hideMobileMenuBottom || hideMobileMenuBottom==false) && showMenuMobile()}
       </>

    )
  }
    return (
        <span>
          {location.pathname!="/shorts"&&<div className={style.menuTopo}><MenuTopo countNovasNotificacoes={countNovasNotificacoes} onClickSearchModal={onClickSearchModal} onClickNotificacoes={onClickNotificacoes} setModalCriarShorts={setModalCriarShorts} setModalCriarAlgo={setModalCriarAlgo} user={user}/></div>}
          {/*Executa o modal postagem unica baseado na url*/}
          {ShowModalPostagemUnicaHandler&&user&&postagemUnicaDados&&<ModalPostagemUnica setPostagemSelecionadaDados={setPostagemUnicaDados} postagemSelecionadaDados={postagemUnicaDados} user={user} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} midia={true} mutado={true}/>}

          {/*O fadeBackground é usado no modal criar Postagem e no criar Shorts*/}
          {fadeBackground && <div className={style.fadeBackground} onClick={onClickFadeBackground}></div>}

          {/*O fadeBackgroundNoColor é usado no search e no notificações*/}
          {fadeBackgroundNoColor && <div className={style.fadeBackgroundNoColor} onClick={onClickFadeBackgroundNoColor}></div>} 

          {/*Abertura dos modais*/}
          {modalCriarAlgo && <ShowModalCriarAlgo onClickFadeBackground={onClickFadeBackground} user={user}/>}
          {modalCriarShorts && <ShowModalCriarShorts user={user} onClickFadeBackground={onClickFadeBackground}/>}

          {/*Chamando menú lateral de acordo com qual menú vc quer*/}
          {menu()}

          {/*Chamando painel search modal, q fica do lado do menú*/}
          {searchModalHandler&&<SearchModal searchModalHandler={searchModalHandler} setSearchModalHandler={setSearchModalHandler} onClickSearchModal={onClickSearchModal}/>}
          {notificacoesHandler && user && <NotificacoesModal notificacoesHandler={notificacoesHandler} onClickNotificacoes={onClickNotificacoes}/>}
        </span>
      );
}