import React, { useState, useRef, useEffect } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import loading from "../../Imgs/LoadSnail.gif";
import axios from 'axios';
import style from "./style.module.css";
import { obterToken } from '../../global';
import apiUrl from "../../global";
import { FaArrowLeft } from 'react-icons/fa';
import ShowUserLittleProfile from '../ShowUserLittleProfile';
function ShowModalCriarYoutube({onClickFadeBackground,user,setCurrentModal}) {
  const [error, setError] = useState("");
  const [isLoadingModal, setIsLoadingModal] = useState(false); //true? está enviando postagem, telinha de carregando
  const [postagem, setPostagem] = useState({
    descricao: "",
    publico: "seguidores",
    linkYoutube: ""
  }); //Resume tudo aq na hr de enviar pro back

  function validarTipoDeLinkYouTube(link) {
    /*
    Consegue identificar: shorts, videos normais e até msm se o vídeo normal tiver dentro de uma playlist
    */
    const regexShorts = /^https:\/\/www\.youtube\.com\/shorts\/[a-zA-Z0-9_-]{11}$/;
    const regexVideoNormal = /^https:\/\/www\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]{11}/;

    if (regexShorts.test(link)) {
        return "short";
    } else if (regexVideoNormal.test(link)) {
        const urlObj = new URL(link);
        const videoID = urlObj.searchParams.get("v");
        return videoID ? `normal` : "invalido";
    } else {
        return "invalido";
    }
  }
 
  async function enviarPostagem() {
    console.log("Chegou aq");
    setIsLoadingModal(true);
    const isValid = validarTipoDeLinkYouTube(postagem.linkYoutube);
    try {
      if(isValid=="invalido"){
        setIsLoadingModal(false);
        return;
      }

      const idToken = await obterToken();
      const payload = {
        descricao: postagem.descricao,
        publico: postagem.publico,
        linkYoutube: postagem.linkYoutube,
      };


      const response = await axios.post(`${apiUrl}/criarLinkYoutube`, payload, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
      });

      setPostagem({ descricao: "", publico:"seguidores", linkYoutube:""});
      onClickFadeBackground();
      window.location.reload();
    } catch (error) {
      if(error.response){
        setError('Erro ao fazer upload da postagem, já estamos cientes e iremos averiguar o problema.');
        console.error("Erro ao fazer upload: ",error.response.data);
      }
    } finally {
      setIsLoadingModal(false);
    }
  }


  return (
    <div className={style.modalPostagem}>
      <div className={style.flexContainer}>

          <div className={style.flexSuperior}> {/*Parte superior*/}
            <div className={style.modalHeader}>
              <FaArrowLeft size={24} className={style.iconVoltar} onClick={()=>setCurrentModal("")}/>
              <p className={style.title}>Link do youtube</p>
              <span onClick={isLoadingModal ? null : enviarPostagem} className={style.iconSend}><FaPaperPlane /></span>
            </div>
            <hr className={style.divisaoHr}/>
          </div>

          <div className={style.flexInferiorModal2}>

            {isLoadingModal ? (
              <div className={style.loadingImg}>
                <p>Você pode continuar usando a plataforma enquanto é feito upload de sua postagem.</p>
                <img src={loading} alt="Loading" />
              </div>
            ) 
            : 
            (
              <div>
                {<ShowUserLittleProfile user={user} naoclicavel={true}/>}
                <textarea name="descricao"
                 placeholder='Máximo 2000 caracteres'
                 maxLength="2000" 
                 value={postagem.descricao}
                 onChange={ (e)=>{setPostagem(prevState=>({...prevState, descricao: e.target.value}))} }>
                </textarea>
                
                <input type="text" 
                  name="linkYoutube" 
                  value={postagem.linkYoutube} 
                  onChange={(e) => setPostagem({ ...postagem, linkYoutube: e.target.value })}
                  className={style.link} 
                  placeholder='Link do youtube - vídeo ou short'
                />

                <div className={style.buttonContainer}>
                  <select className={style.escolherPublico} value={postagem.publico} onChange={ (e) => setPostagem(prevState => ({ ...prevState, publico: e.target.value })) }>
                    <option value="seguidores">Seguidores, amigos e perfil</option>
                    <option value="global">Global</option>
                  </select>
                </div>
                <p className={style.error}>{error}</p>
              </div>
            )}

          </div>
      </div>
    </div>
  );
}

export default ShowModalCriarYoutube;
