import { useState, useEffect,useRef  } from 'react';
import style from "./index.module.css";
import { Navigate, useNavigate } from 'react-router-dom';
import { handleLogout } from '../../../global';
import {AiOutlineSearch, AiOutlineBell, AiOutlinePlus} from 'react-icons/ai';
import { FiVideo  } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import { BiLogOut } from 'react-icons/bi';
export default function MenuTopo({onClickNotificacoes, setModalCriarAlgo, setModalCriarShorts, user,onClickSearchModal, countNovasNotificacoes}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  /*Combo para desativar menú ao clicar fora dele */
  const menuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false); // Fecha o menu ao clicar ou arrastar fora
    }
  };
  useEffect(() => {
    // Adiciona o event listener para cliques no documento (onMouseDown)
    document.addEventListener('mousedown', handleClickOutside);

    // Remove o listener ao desmontar o componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
/*Fim combo desativar menú ao clicar fora dele */



  return (
    <div className={style.container} ref={menuRef}>
      <span className={style.rowFlex}>
        <div className={style.hamburger} onClick={toggleMenu}>
          <div className={style.line}></div>
          <div className={style.line}></div>
          <div className={style.line}></div>
        </div>
        {countNovasNotificacoes>0&&<span className={style.NotificacaoNumeroShort}>{countNovasNotificacoes}</span>}

      </span>

      {isOpen && (
        <div className={style.dropdownMenu}>

          {/*Logo*/}
          <div className={style.hevelim}> <p>Hevelim</p> </div>

            <div className={style.divIconShort} onClick={onClickSearchModal}>
              <AiOutlineSearch className={style.icon} />
              <p>Pesquisar</p>
            </div>

            {/*Menu notificacoes*/}
            <div className={style.divIconShort} onClick={onClickNotificacoes}>
              <AiOutlineBell className={style.icon}/>
              <p>Notificações</p>
              {countNovasNotificacoes>0&&<span className={style.NotificacaoNumeroShort2}>{countNovasNotificacoes}</span>}
            </div>
            
            {/* Criar Shorts
            <div className={style.divIconShort} onClick={()=>{setModalCriarShorts(true);setIsOpen(false);}}>
              <FiVideo className={style.icon} /><p>Criar shorts</p>
            </div>  */}

            {/*Criar postagens*/}
            <div className={style.divIconShort} onClick={()=>{setModalCriarAlgo(true);setIsOpen(false);}}>
              <AiOutlinePlus className={style.icon} /><p>Criar publicação</p>
            </div>

              {/*Logout*/}
            <div className={style.divIconShort} onClick={()=>handleLogout(navigate)}>
              <BiLogOut className={style.icon} /><p>Desconectar</p>
            </div>



            {/*Moderador*/}
            {
              (user.claims=="admin" || user.claims=="moderador")&&
              <div className={style.divIconShort} onClick={()=>{navigate('/moderador')}}>
                <FaStar className={style.icon} />
                <p>Moderador</p>
              </div>
            }

             {/*Ajudante*/}
             {
              (user.claims=="admin" || user.claims=="moderador")&&
              <div className={style.divIconShort} onClick={()=>{navigate('/ajudante');}}>
                <FaStar className={style.icon} />
                <p>Mod ajudante</p>
              </div>
            }

            {/* <ul>
            <li onClick={()=>{setModalCriarShorts(true);setIsOpen(false);}}>Criar shorts</li>
            <li onClick={()=>{setModalCriarPostagem(true);setIsOpen(false);}}>Criar postagem</li>
            {
              (user.claims=="admin" || user.claims=="moderador")&&
              <li onClick={()=>{navigate('/moderador')}}>Moderador</li>
            }
            <li onClick={()=>handleLogout(navigate)}>Sair</li>
            {/* Adicione mais itens aqui se precisar */}
        </div>
      )}
    </div>
  );
}
