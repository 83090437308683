import React, { useState, useEffect, useRef  } from 'react';

export default function ExibirYoutubeLink({ linkExterno }) {
    /*
    Ao entrar em tela cheia, foi feito que o intersect pare, pois senao buga, afinal o video full screen é tipo um fixed,
    daí os de baixo tavam subindo pra cima e executando por entrar no intersect.
    */
    const [embedUrl, setEmbedUrl] = useState('');
    const iframeRef = useRef(null);
    const observerRef = useRef(null);

    // Função para extrair o ID do vídeo do link
    const extractVideoId = (url) => {
        if (url.includes('youtube.com/shorts/')) {
            return url.split('/').pop();
        }
        const match = url.match(/(?:youtube\.com\/(?:[^\/]+\/[^\/]+|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match ? match[1] : null;
    };

    const videoId = extractVideoId(linkExterno);

    useEffect(() => {
        if (videoId) {
            setEmbedUrl(`https://www.youtube.com/embed/${videoId}?autoplay=0&autohide=1&rel=0&modestbranding=1`);

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setEmbedUrl(`https://www.youtube.com/embed/${videoId}?autoplay=1&autohide=1&rel=0&modestbranding=1`);
                    } else {
                        setEmbedUrl(`https://www.youtube.com/embed/${videoId}?autoplay=0&autohide=1&rel=0&modestbranding=1`);
                    }
                });
            }, { threshold: 0.6 });
            
            observerRef.current = observer;

            if (iframeRef.current) {
                observer.observe(iframeRef.current);
            }

            const handleFullscreenChange = () => {
                if (document.fullscreenElement) {
                    observer.disconnect(); // Desativa o IntersectionObserver
                } else {
                    if (iframeRef.current) {
                        observer.observe(iframeRef.current); // Reativa o IntersectionObserver
                    }
                }
            };

            document.addEventListener('fullscreenchange', handleFullscreenChange);

            return () => {
                if (iframeRef.current) {
                    observer.unobserve(iframeRef.current);
                }
                document.removeEventListener('fullscreenchange', handleFullscreenChange);
                observer.disconnect();
            };
        }
    }, [videoId]);

    return (
        <div>
            {videoId ? (
                <iframe
                    ref={iframeRef}
                    width="100%"
                    height="500"
                    src={embedUrl}
                    title="YouTube video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            ) : (
                <p>Link de vídeo inválido</p>
            )}
        </div>
    );
}