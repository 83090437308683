import React, { useState, useEffect } from 'react';
import style from "./style.module.css";
import criarPostagem from "../../Imgs/criarPostagem.png";
import criarShorts from "../../Imgs/criarShorts.png";
import criarYoutube from "../../Imgs/criarYoutube.png";
import ModalCriarPostagem from "../ShowModalCriarPostagem";
import ModalCriarShorts from "../ShowModalCriarShorts";
import ModalCriarYoutube from "../ShowModalCriarYoutube";
import { FaArrowLeft } from 'react-icons/fa';

function ShowModalCriarAlgo({onClickFadeBackground,user}) {
  const [currentModal, setCurrentModal] = useState("");
  return (
    <div className={style.modalPostagem}>
       <div className={style.flexContainer}> {/*Flex column*/}
        
        <div className={style.flexSuperior}> {/*Parte superior*/}
          <div className={style.modalHeader}>
            <FaArrowLeft size={24} className={style.iconVoltar} onClick={()=>onClickFadeBackground()}/>
            <p className={style.title}>Criar nova publicação</p>
            <span/>
          </div>
          <hr className={style.divisaoHr}/>
        </div>

        <div className={style.threeOptions}>{ /*Parte inferior, flex column center*/}
          {
            !currentModal?
              <>
                <img className={style.op} src={criarPostagem} onClick={()=>setCurrentModal("criarPostagem")}/>
                <img className={style.op} src={criarShorts} onClick={()=>setCurrentModal("criarShorts")}/>
                <img className={style.op} src={criarYoutube} onClick={()=>setCurrentModal("criarYoutube")}/>
              </>
            :
            currentModal=="criarPostagem"?
            <ModalCriarPostagem onClickFadeBackground={onClickFadeBackground} user={user} setCurrentModal={setCurrentModal}/>
            :
            currentModal=="criarShorts"?
            <ModalCriarShorts onClickFadeBackground={onClickFadeBackground} user={user} setCurrentModal={setCurrentModal}/>
            :
            currentModal=="criarYoutube"&&
            <ModalCriarYoutube onClickFadeBackground={onClickFadeBackground} user={user} setCurrentModal={setCurrentModal}/>
          }
            
        </div>
      </div>
    </div>
  );
}

export default ShowModalCriarAlgo;
